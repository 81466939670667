.invite-user__field {
  display: flex;
  align-items: center;
  width: 400px;
}

.invite-user__field__name {
  min-width: 130px;
  margin-right: 50px;
  text-align: left;
}

.lf-form-switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
}