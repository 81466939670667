.horizontal-bar-chart--split .horizontal-bar-chart__label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.horizontal-bar-chart--split .horizontal-bar-chart__percent-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
}

.horizontal-bar-chart--split .horizontal-bar-chart__percentage {
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  letter-spacing: 0.1px;
  width: 40px;
  color: var(--text-1);
}

.horizontal-bar-chart--split .horizontal-bar-chart__bar-filled {
  border-radius: 4px 4px 4px 4px;
}

.horizontal-bar-chart--split .horizontal-bar-chart__bar-empty {
  background-color: #FF3B30;
  border-radius: 4px 4px 4px 4px;
}
.horizontal-bar-chart--split .horizontal-bar-chart__percentage--right {
  text-align: right;
}

.horizontal-bar-chart--split .horizontal-bar-chart__label {
  flex: 1;
  font-weight: 700;
}

.horizontal-bar-chart--split .horizontal-bar-chart__bar-container {
  gap: 4px;
  background-color: transparent;
}

.horizontal-bar-chart__bar-container {
  display: flex;
  width: 100%;
  height: 6px;
  background-color: #6c6c8929;
  border-radius: 4px;
  overflow: hidden;
}

.horizontal-bar-chart__bar-filled {
  height: 100%;
  background-color: #2d7ffa;
  transition: width 0.3s ease;
}

.horizontal-bar-chart__bar-empty {
  height: 100%;
  background-color: #ccc;
  transition: width 0.3s ease;
}

.statistic-chart__list {
  margin: 10px 0;
  padding: 0;
  list-style: none;
}

.statistic-chart__item {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.statistic-chart__label {
  flex: 1;
  text-align: left;
}

.statistic-chart__value {
  flex: 1;
  text-align: right;
}

.statistic-chart {
  padding: 8px 0px;
  font-family: Arial, sans-serif;
}

.statistic-chart__bars {
  width: 100%;
}

.horizontal-bar-chart {
  width: 100%;
}

.horizontal-bar-chart:not(:last-child) {
  margin-bottom: 16px;
}

.horizontal-bar-chart__label-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.horizontal-bar-chart__label,
.horizontal-bar-chart__percentage {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: 0.1px;
}

.horizontal-bar-chart__label {
  text-align: left;
}

.horizontal-bar-chart__percentage {
  text-align: right;
}

.horizontal-bar-chart__bar-container {
  width: 100%;
  height: 6px;
  background-color: #6c6c8929;
  border-radius: 4px;
  overflow: hidden;
}

.horizontal-bar-chart__bar-filled {
  height: 100%;
  background-color: #2d7ffa;
  border-radius: 4px 0 0 4px;
  transition: width 0.3s ease;
}

.horizontal-bar-chart--unsupported {
  display: flex;
  align-items: center;
  height: 46px;
  border: 1px solid #6C6C8929;
  border-radius: 8px;
  font-size: 12px;
  color: #B2B2C7;
  background-color: #ffffff;
  text-align: left;
  padding-left: 19px;
}
